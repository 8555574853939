/* Container for the scrolling banner */
#container {
  height: 200px; /* Matches the image height */
  position: relative;
  overflow: hidden;
  background-color: transparent; /* Transparent background */
}

/* Banner holding the images */
.photobanner {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap; /* Ensure images are in one line */
  animation: bannermove 10s linear infinite; /* Continuous animation */
}

/* Individual image styles */
.photobanner img {
  width: 150px; /* Fixed width */
  height: 150px; /* Fixed height */
  margin: 0 0.5em; /* Space between images */
  display: inline-block; /* Ensure images are inline */
  object-fit: contain; /* Maintain aspect ratio inside the box */
  background-color: transparent; /* Ensure the image background is transparent */
}

/* Keyframe animation for smooth scrolling */
@keyframes bannermove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0); /* Move by 50% for infinite scrolling */
  }
}
