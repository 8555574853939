.tp-team-slider-wrapper {
  display: flex;
  overflow-x: auto; /* Horizontal scroll */
  scroll-behavior: smooth; /* Smooth scrolling */
  white-space: nowrap;
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.tp-team-slider-wrapper.no-scrollbar::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

.brand-slider {
  display: flex;
  align-items: center;
}

.brand-slide {
  flex: 0 0 auto;
  margin: 0 10px; /* Space between slides */
}

.brand-image {
  width: 270px;
  height: 440px;
  object-fit: cover; /* Ensures the image fits properly */
  border-radius: 8px; /* Optional styling */
}
